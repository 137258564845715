import { useState, useEffect } from "react";

const UpdateForm = ({ taskToken, initialMessage, onUpdate }) => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    setMessage(initialMessage || "");
  }, [initialMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(taskToken, message);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows={20}
          cols={125}
          style={{ whiteSpace: "pre-wrap" }}
        />
      </div>
      <div>
        <button type="submit">Update</button>
      </div>
    </form>
  );
};

export default UpdateForm;
