import { useState, useEffect } from "react";
import UpdateForm from "./components/UpdateForm";

const App = () => {
  const [taskToken, setTaskToken] = useState("");
  const [initialMessage, setInitialMessage] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setTaskToken(searchParams.get("taskToken"));
    setInitialMessage(searchParams.get("message"));
  }, []);

  const handleUpdate = async (taskToken, updatedMessage) => {
    try {
      const response = await fetch(
        `https://api.dev.integralhealth.me/v1/delivery/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedMessage, taskToken }),
        }
      );
      console.log("response from update", JSON.stringify(response));

      if (response.ok) {
        alert("Message updated successfully");
      } else {
        alert("Failed to update the message");
      }
    } catch (error) {
      console.error("Error updating message:", error);
      alert("Failed to update the message");
    }
  };

  return (
    <div>
      <h1>Update Sleep Plan Message</h1>
      <UpdateForm
        taskToken={taskToken}
        initialMessage={initialMessage}
        onUpdate={handleUpdate}
      />
    </div>
  );
};

export default App;
